import React, { memo, useEffect } from 'react'
import { useRouter } from 'next/router'
import { useYoutube } from '../../../hooks/useYoutube'
import { useDispatch } from 'react-redux'
import { urlReducer } from '../../utils/appSlice'
import { Footer } from '../footer'
import { Header } from '../header'

export const Layout = memo((props: { children: React.ReactNode }) => {
  const router = useRouter()
  const dispatch = useDispatch()
  useYoutube(router)

  useEffect(() => {
    if (!sessionStorage.getItem('firstViewURL')) {
      sessionStorage.setItem(
        'firstViewURL',
        location.href.split(location.host)[1]
      )

      dispatch(urlReducer(location.href.split(location.host)[1]))
    }
  }, [dispatch])

  return (
    <>
      <Header path={router.asPath} />
      <div className="l-wrapper">
        <main>{props.children}</main>
      </div>
      <Footer />
    </>
  )
})

Layout.displayName = 'Layout'
