import '../styles/globals.scss'
import '../styles/index.css'

import React from 'react'
import { AppProps } from 'next/app'
import { Provider } from 'react-redux'
import { store } from '../store/store'

import useDeploy from '../hooks/useDeploy'
import { Layout } from '../components/layouts/layout'

function App({ Component, pageProps }: AppProps) {
  useDeploy()

  return (
    <Provider store={store}>
      <Layout>
        {/*// @ts-ignore*/}
        <Component {...pageProps} />
      </Layout>
    </Provider>
  )
}

export default App
