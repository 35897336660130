import React, { useEffect } from 'react'

function loadVideo() {
  setTimeout(() => {
    const video = document.querySelector('.video')

    if (video === null) return
    const videoElement = video.firstElementChild

    if (videoElement instanceof Element) {
      const video_id = videoElement.getAttribute('id')

      if (video_id !== null) {
        const youtubeIframe = document.getElementById(video_id)

        if (youtubeIframe && youtubeIframe instanceof HTMLElement) {
          let ytPlayer
          function onYouTubeIframeAPIReady() {
            if (youtubeIframe && youtubeIframe instanceof HTMLElement) {
              const iframeId = youtubeIframe.getAttribute('data-iframe')
              const videoId = youtubeIframe.getAttribute('data-id')
              const start = youtubeIframe.getAttribute('data-start')

              //@ts-ignore
              ytPlayer = new YT.Player(iframeId, {
                width: 560,
                height: 315,
                videoId: videoId,
                playerVars: {
                  loop: 1,
                  playlist: videoId,
                  // controls: 0,
                  start: start,
                  playsinline: 1,
                },
                events: {
                  onReady: onPlayerReady,
                  onStateChange: onPlayerStateChange,
                },
              })
            }
          }

          onYouTubeIframeAPIReady()

          function onPlayerStateChange(event) {
            const ytStatus = event.target.getPlayerState()
            // @ts-ignore
            if (ytStatus == YT.PlayerState.ENDED) {
              event.target.mute() //ミュートにしないとスマホで再生しない
              event.target.playVideo() //ビデオを再生
            }
          }

          function onPlayerReady(event) {
            const options = {
              root: null,
              rootMargin: '0% 0px',
              threshold: 0,
            }
            const observer = new IntersectionObserver(doWhenIntersect, options)
            const boxes = document.querySelectorAll('.observe')
            boxes.forEach((box) => {
              observer.observe(box)
            })

            function doWhenIntersect(entries) {
              entries.forEach((entry) => {
                if (entry.isIntersecting) {
                  event.target.mute()
                  event.target.playVideo()
                } else {
                  event.target.pauseVideo()
                }
              })
            }
          }
        }
      }
    }
  }, 500)
}

export const useYoutube = (router) => {
  useEffect(() => {
    // @ts-ignore
    if (!window.YT) {
      const tag = document.createElement('script')
      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      if (firstScriptTag.parentNode) {
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)
        tag.onload = loadVideo
      }
    } else {
      loadVideo()
    }
  }, [router])
}
